import React from 'react'
import Layout from '../../components/Layout'
import { Link } from 'gatsby'

export default () => (
  <Layout>
    <section className="section">
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <br />
            <h2 className="title is-size-2 is-bold-light">
              Thank you!
            </h2>
            <h5 className="is-size-5">
              I'll be in touch, soon!
            </h5>

            <br />
            <br />
            <br />
            <Link className="link is-size-4" to="/">
              Home
            </Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
